:host {
    --color: #edeef0
}

.clock-block input {
    background: #edeef0;
    border: 1px solid #edeef0;
    padding: 5px;
    margin: 2px;
    border-radius: 6px;
    font-size: 14px;
    height: 40px;
    width: 150px;
}

.clock-block input[type="time"] {
    height: 35px;
}

.clock-block input:focus {
    outline: none;
}

.clock-block input:focus::placeholder {
    color: #edeef0;
}

.clock-block input:focus::-webkit-input-placeholder {
    color: #edeef0;
}

.clock-block input:focus:-moz-placeholder {
    color: #edeef0;
}

.clock-select select {
    background: #edeef0;
    border: 1px solid #edeef0;
    padding: 5px;
    margin: 2px;
    border-radius: 6px;
    font-size: 14px;
    height: 40px;
    width: 150px;
}

.clock-select select:focus {
    outline: none;
}

.clock-select select:focus::placeholder {
    color: #edeef0;
}

.clock-select select:focus::-webkit-input-placeholder {
    color: #edeef0;
}

.clock-select select:focus:-moz-placeholder {
    color: #edeef0;
}

@media screen and (max-width: 768px) {
    @media (prefers-color-scheme: dark) {
        .clock-select select {
            background: #48494a;
            border: 1px solid #48494a;
        }

        .clock-block input {
            background: #48494a;
            border: 1px solid #48494a;
        }
    }

    @media (prefers-color-scheme: light) {
        .clock-select select {
            background: #edeef0;
            border: 1px solid #edeef0;
        }

        .clock-block input {
            background: #edeef0;
            border: 1px solid #edeef0;
        }
    }
}