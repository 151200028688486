.ionic-header {
    text-align: center;
    background-image: url('../../public/assets/sliders/bgi.jpg');
    background-position: 0px -300px;
    background-size: cover;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mr-bikes-logo {
    width: 200px;
    object-fit: contain;
    margin: 30px 0px;
}

.caption {
    text-align: center;
    margin-bottom: 40px;
}

.caption h1 {
    color: #ff350f;
    font-size: 2.2rem;
    font-weight: 700;
    padding: 10px;
    text-transform: uppercase;
}

.caption address {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0px;
    text-transform: capitalize;
}


@media screen and (max-width: 768px) {
    .caption h1 {
        font-size: 1.5rem;
    }
    .mr-bikes-logo {
        width: 190px;
        margin: 0px 0px;
        position: relative;
        top: -100px;
    }

    .caption address {
        font-size: 1.2rem;
    }
}