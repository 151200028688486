.add-border-right {
    border-right: 1px solid #ccc;
    margin: 5px 0px;
    padding: 0px 5px;
}

.remove-right-side-border {
    border-right: none;
}

.td-row-first-row {
    border: 1px solid #c3c3c3;
    border-right: 0px;
    border-left: 0px;
}

.td-row {
    border-bottom: 1px solid #c3c3c3;
}

.bikes-display-section .section-caption{
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    color: #ff350f;
    text-align: center;
    display: block;
    margin: 20px auto;
    width: fit-content;
    border-bottom: 2px solid;
}

.bikes-display-section .section-capt{
    font-weight: 400;
    text-align: center;
    padding: 0px 10px;
}

.map-timings ul li{
    list-style: none;
    margin: 10px 0px;
    display: block;
}

.map-timings {
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    text-align: center ;
}

@media screen and (max-width: 768px) {
    .bikes-display-section .section-caption{
        font-size: 1.1rem;
    }

    .section-capt {
        font-size: 0.8rem;
    }
}

.ion-select {
    width: 53%;
    height: 33px;
    border: 1px solid #000;
    border-radius: 8px;
    font-size: 14px;
    padding: 0px 10px;
}

.card-content-md p{
    margin-bottom: 0 !important;
    font-size: 12px !important;
}