.container {
  padding: 10px;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

ion-checkbox {
  --size: 26px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
}

ion-radio {
  width: 30px;
  height: 30px;
}

ion-radio::part(container) {
  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--ion-color-primary);
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}